/* inspired by: https://css-tricks.com/responsive-data-tables/ */

.header h2{
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
}
.responsiveTable {
    width: 100%;
    margin: 30px 0px;
    border-collapse: separate; 
  border-spacing: 10px;
    
  }

  .responsiveTable td {
    padding: 10px 10px 10px 20px;
    
  }

  
  .responsiveTable td .tdBefore {
    display: none;
  }
  .responsiveTable tr{
    border: 1px #56148a solid;
    border-radius: 5px;
  /*box-shadow: 0px 0px 10px #56148a inset;*/
  box-shadow: 0.25em 0.25em  0.25em  rgba( 0, 0, 0, 0.4 );
  
  
   }

  .responsiveTable td i{
    color:#1eb36a;
   }
   .responsiveTable .testimonial{
    font-style: italic;
    padding-left: 20px;
    }
    .responsiveTable .name{
        font-weight: bold;
       padding-top: 10px;
        }
        .responsiveTable .designation{
          
            font-style: italic;
            margin-top: -20px;
            }

           .rounded-corners {
                border-radius : 50%;
             
              }

              ul {
                list-style: none;
              }
              
              .pagination {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 50px;
                flex-wrap: wrap;
              }
              
              .pagination .page-number {
                font-size: 14px;
                font-weight: 600;
                color: #56148a;
                background: #fff;
                padding: 10px 9px;
                border-radius: 10px;
                cursor: pointer;
                transition: all 0.5s ease;
              }
              
              .pagination .page-number:hover {
                color: #fff;
                background: #56148a;
              }
              
              .pagination .active {
                color: #fff;
                background: #56148a;
              }
              
              .pagination .active:hover {
                color: #56148a;
                background: #fff;
              }

              .loading {
                padding: 20px 0px;
                display: flex;
                text-align: center;
                align-content: center;
                justify-content: center;
                
              }

            
  @media screen and (max-width: 700px) {
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */
    
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }
    
    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      border-bottom: 2px solid #333;
    }
  
    .responsiveTable tbody tr {
      border: 1px solid #000;
      padding: .25em;
      text-align: center;
      margin-top: 5px;
    }
    .responsiveTable tbody tr .align {
        text-align: center;
      }
  
    .responsiveTable td.pivoted {
      /* Behave like a "row" */
      border: none !important;
      position: relative;
      padding-left: calc(0% + 10px) !important;
      /*text-align: left !important;*/
      white-space: pre-wrap;
      overflow-wrap: break-word;
      padding: 10px 0px;
    }
    
  
    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;
  
      /* Top/left values mimic padding */
      left: 1rem;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }
  }
  