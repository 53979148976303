
body {
    font-family:'Poppins', sans-serif;
    color: #0c0c0c;
    background-color: #ffffff;
    overflow-x: hidden;
  }
  
  
  
  .layout_padding {
    padding: 0px 0;
  }
  
  .layout_padding2 {
    padding: 75px 0 0 0;
  }
  
  .layout_padding2 {
    padding: 75px 0;
  }
  
  
  .layout_padding2-top {
    padding-top: 75px;
  }
  
  .layout_padding2-bottom {
    padding-bottom: 75px;
  }
  
  .layout_padding-top {
    padding-top: 90px;
  }
  
  .layout_padding-bottom {
    padding-bottom: 90px;
  }

  
/*HEADER SECTIOM */

.header_section .header_top {
    padding: 15px 0 ;
    border-bottom: 1px solid #ffffff;
      background-color:#56148A;
      font-family: 'Abel';
      font-size: 20px;
  }
  
  .header_section .header_top .header_top_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .header_section .header_top .contact_nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  
  .header_section .header_top .contact_nav a {
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
      margin-right: 5px;
      
      
  }
  .header_section .header_top .contact_nav a:hover {
    color: #cf2880;
  }
  .header_section .header_top .contact_nav2 {
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
      margin-right: 5px;
      
      
  }
  .header_section .header_top .contact_nav2:hover {
    color: #cf2880;
  }
  .header_section .header_top .contact_nav2  i {
    margin-right: 5px;
    background-color:#e5e5e5;
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #56148a;
    border-radius: 100%;
      
  }
  
  .header_section .header_top .contact_nav2:hover i {
    background-color: #cf2880;
  }
  
  .header_section .header_top .contact_nav a i {
    margin-right: 5px;
    background-color:#e5e5e5;
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #56148a;
    border-radius: 100%;
      
  }
  
  .header_section .header_top .contact_nav a:hover i {
    background-color: #cf2880;
  }
  
  
  .header_section .header_top .social_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    min-width: 200px;
  }
  
  .header_section .header_top .social_box a i {
    margin-right: 5px;
    background-color: #159ec0;
    width: 28px;
    height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #ffffff;
    border-radius: 100%;
  }
  
  .header_section .header_top .social_box a:hover i {
    background-color: #56148a;
  }
  
  .header_section .header_bottom .header_top {
    padding: 15px 0 ;
  }
  
  .header_section .header_bottom .header_top .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
  
  .header_section .header_top {
    padding: 0 0;
  }
  
  .header_section .header_top .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }

  .head_section .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
      
  }
  
  .head_section .img-box {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  
  .head_section .img-box img {
    width: 100%;
  }
  
  .head_section .detail-box {
    font-size: 18px;
  }
  
  .head_section .detail-box p {
    color: #1f1f1f;
    margin-top: 15px;
  }
  
  .head_section .detail-box a {
    display: inline-block;
    padding: 10px 35px;
    background-color: #56148a;
    color: #ffffff;
    border-radius: 0px;
    -webkit-transition: all .3s;
    transition: all .3s;
    border: 1px solid #56148a;
    margin-top: 15px;
  }
  
  .head_section .detail-box a:hover {
    background-color: transparent;
    color: #56148a;
  }

/*NAVBAR*/

  .custom_nav-container .nav_search-btn {
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
    color: #ffffff;
  }
  
  .custom_nav-container .nav_search-btn:hover {
    color: #56148a;
  }
  
  .custom_nav-container .navbar-toggler {
    outline: none;
  }
  
  .custom_nav-container .navbar-toggler {
    padding: 0;
    width: 37px;
    height: 42px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .custom_nav-container .navbar-toggler span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #ffffff;
    margin: 7px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
  }
  
  .custom_nav-container .navbar-toggler span::before, .custom_nav-container .navbar-toggler span::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    top: -10px;
    border-radius: 5px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .custom_nav-container .navbar-toggler span::after {
    top: 10px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] span {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] span::before, .custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    top: 0;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: 0;
    margin-bottom: -4px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
    display: none;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin: 0;
    margin-top: -4px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
    -webkit-transform: none;
            transform: none;
  }
  

.navbar-brand {
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
      padding-left: 20px;
  }
  
  .navbar-brand span {
    color: #56148a;
  }
  
  .navbar-brand:hover {
    color: inherit;
  }

/*FOOTER SECTION */

.info_section {
    background-color: #56148a;
    color: #ffffff;
    padding: 45px 0 10px 0;
  }
  
  .info_section h5 {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .info_section .info_top {
    margin-bottom: 25px;
  }
  
  .info_section .info_top .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .info_section .info_contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-width: 245px;
  }
  
  .info_section .info_contact a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #ffffff;
  }
  
  .info_section .info_contact a i {
    margin-right: 5px;
    font-size: 24px;
  }
  
  .info_section .social_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  
  .info_section .social_box a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    color: #ffffff;
    margin-right: 5px;
    font-size: 24px;
  }
  
  .info_section .info_bottom .row > div {
    margin-bottom: 30px;
  }
  
  .info_section .info_menu .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-left: 0;
  }
  
  .info_section .info_menu .nav-link:hover {
    color: #cf2880;
  }
  
  .info_section .info_menu li {
    list-style-type: none;
    margin-bottom: 10px;
  }
  
  .info_section .info_menu li a {
    color: #ffffff;
  }
  
  .info_section .info_form input {
    width: 100%;
    border: none;
    height: 45px;
    margin-bottom: 25px;
    padding-left: 25px;
    background-color: #eaeaea;
    outline: none;
    color: #101010;
  }
  
  .info_section .info_form button {
    display: inline-block;
    padding: 10px 45px;
    background-color:#868484;
    color: #ffffff;
    border-radius: 5px;
    -webkit-transition: all .3s;
    transition: all .3s;
    border: 1px solid #252525;
  }
  
  .info_section .info_form button:hover {
    background-color:#868484;
    color: #56148a;
  }
  
  
  .info_section .social_box .nav-link {
    padding: 5px 30px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 25px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  
  
  .info_section .social_box .nav-link {
    color: #fff;
  }
  .info_section .social_box .nav-link:hover {
    color: #cf2880;
  }


 .testimonial_slide {
  background: none;
  align-items: center;
  justify-content: center;
  color: #56148A;
 }